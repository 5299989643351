import {
    PageWithHeader,
    Content
  } from '@backstage/core-components';
  import { useApi, configApiRef } from '@backstage/core-plugin-api';
  import {
    EntityListProvider,
    CatalogFilterLayout,
    EntityKindPicker
  } from '@backstage/plugin-catalog-react';
  import React from 'react';
import { CatalogTable } from './CatalogTable/CatalogTable';
  
  export const CustomCatalogIndexPage = () => {
    const orgName =
      useApi(configApiRef).getOptionalString('organization.name') ?? 'Backstage';
  
    return (
      <PageWithHeader title={orgName} themeId="home">
        <Content>
          <EntityListProvider>
            <CatalogFilterLayout>
              <CatalogFilterLayout.Filters>
                <EntityKindPicker />
              </CatalogFilterLayout.Filters>
              <CatalogFilterLayout.Content>
                <CatalogTable />
              </CatalogFilterLayout.Content>
            </CatalogFilterLayout>
          </EntityListProvider>
        </Content>
      </PageWithHeader>
    );
  };