import { TableColumn } from '@backstage/core-components';
import { CatalogTableColumnsFunc, CatalogTableRow } from './types';
import { columnFactories } from './columns';

// The defaultCatalogTableColumnsFunc symbol is not directly exported, but through the
// CatalogTable.defaultColumnsFunc field.
/** @public */
export const defaultCatalogTableColumnsFunc: CatalogTableColumnsFunc = ({
  filters,
  entities,
}) => {
  const showTypeColumn = filters.type === undefined;

  return [
    columnFactories.createTitleColumn({ hidden: true }),
    columnFactories.createNameColumn({ defaultKind: filters.kind?.value }),
    ...createEntitySpecificColumns(),
    columnFactories.createMetadataDescriptionColumn(),
    columnFactories.createTagsColumn(),
  ];

  function createEntitySpecificColumns(): TableColumn<CatalogTableRow>[] {
    const baseColumns = [
      columnFactories.createSystemColumn(),
      columnFactories.createOwnerColumn(),
      columnFactories.createSpecTypeColumn({ hidden: !showTypeColumn }),
    ];
    switch (filters.kind?.value) {
      case 'user':
        return [];
      case 'domain':
      case 'system':
        return [columnFactories.createOwnerColumn()];
      case 'group':
      case 'template':
        return [
          columnFactories.createSpecTypeColumn({ hidden: !showTypeColumn }),
        ];
      case 'location':
        return [
          columnFactories.createSpecTypeColumn({ hidden: !showTypeColumn }),
          columnFactories.createSpecTargetsColumn(),
        ];
      default:
        return entities.every(entity => entity.metadata.namespace === 'default')
          ? baseColumns
          : [...baseColumns, columnFactories.createNamespaceColumn()];
    }
  }
};