/* eslint-disable new-cap */
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { googleAuthApiRef } from '@backstage/core-plugin-api';

import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { UserSettingsAuthProviders, UserSettingsGeneral, UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { myTheme } from './theme';
import LightIcon from '@material-ui/icons/WbSunny';

import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import './App.css';
import { SingleSignInPage } from './components/Sign/SignInPageCustom';
import { CustomCatalogIndexPage } from './components/CustomCatalogIndexPage/CustomCatalogIndexPage';
import { SettingsLayout } from './components/SettingsLayout.tsx/SettingsLayout';

const app = createApp({
  themes: [{
      id: 'my-theme',
      title: 'My Custom Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <ThemeProvider theme={myTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    }],
  components: {
    SignInPage: props => (
      <SingleSignInPage
        {...props}
        auto
        provider={{
          id: 'google-auth-provider',
          title: 'Google',
          message: 'Faça login usando o Google',
          apiRef: googleAuthApiRef,
        }}
      />
    ),
  },
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage />}>
      <CustomCatalogIndexPage />
    </Route>
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/create" element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <ScaffolderPage />
        </RequirePermission>}
    />
    <Route path="/catalog-import" element={<CatalogImportPage />} />
    <Route path="/settings" element={<UserSettingsPage />}>
      <SettingsLayout>
        <SettingsLayout.Route path="general" title="General">
          <UserSettingsGeneral />
        </SettingsLayout.Route>
        <SettingsLayout.Route
          path="auth-providers"
          title="Authentication Providers"
        >
          <UserSettingsAuthProviders />
        </SettingsLayout.Route>
      </SettingsLayout>
    </Route>
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
    <Root>{routes}</Root>
    </AppRouter>
  </>,
);
