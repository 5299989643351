import {
    createTheme,
    genPageTheme,
    lightTheme,
    shapes,
  } from '@backstage/theme';
  
 export const myTheme = createTheme({
    palette: {
      ...lightTheme.palette,
      primary: {
        main: '#555AD5',
      },
      secondary: {
        main: '#12A068',
      },
      error: {
        main: '#8c4351',
      },
      warning: {
        main: '#006F77',
      },
      info: {
        main: '#12A068',
      },
      success: {
        main: '#12A068',
      },
      background: {
        default: '#ffffff',
        paper: '#ffffff',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#343b58',
        link: '#12A068',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
      navigation: {
        background: '#555AD5',
        indicator: '#ffffff',
        color: '#ffffff',
        selectedColor: '#ffffff',
        navItem: {
            hoverBackground: '#ffffff47',
        },
      },
    },
    defaultPageTheme: 'home',
    fontFamily: 'Roboto, sans-serif',
    /* below drives the header colors */
    pageTheme: {
      home: genPageTheme({ colors: ['#555AD5', '#555AD5'], shape: shapes.wave }),
      documentation: genPageTheme({
        colors: ['#555AD5', '#555AD5'],
        shape: shapes.wave2,
      }),
      tool: genPageTheme({ colors: ['#555AD5', '#555AD5'], shape: shapes.round }),
      service: genPageTheme({
        colors: ['#555AD5', '#555AD5'],
        shape: shapes.wave,
      }),
      website: genPageTheme({
        colors: ['#555AD5', '#555AD5'],
        shape: shapes.wave,
      }),
      library: genPageTheme({
        colors: ['#555AD5', '#555AD5'],
        shape: shapes.wave,
      }),
      other: genPageTheme({ colors: ['#555AD5', '#555AD5'], shape: shapes.wave }),
      app: genPageTheme({ colors: ['#555AD5', '#555AD5'], shape: shapes.wave }),
      apis: genPageTheme({ colors: ['#555AD5', '#555AD5'], shape: shapes.wave }),
    },
  });