import React from 'react';
import { EntitySearchBar } from '@backstage/plugin-catalog-react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useToolbarStyles = makeStyles(
  theme => ({
    root: {
      paddingTop: theme.spacing(1.25),
      paddingLeft: theme.spacing(2.5),
      paddingBottom: theme.spacing(0.75),
      display: 'flex',
      justifyContent: 'space-between',
    },
    text: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
  { name: 'BackstageTableToolbar' },
);

export function CatalogTableToolbar(props: {
  title?: string | React.ReactElement<any>;
}) {
  const styles = useToolbarStyles();
  return (
    <Toolbar className={styles.root}>
      <Typography variant="h5" className={styles.text}>
        {props.title}
      </Typography>
      <EntitySearchBar />
    </Toolbar>
  );
}