import React, { PropsWithChildren } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import LogoFull from './LogoFull';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState,
  Link,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';

import Package from '../../../package.json';
import { usePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? (
          <Box sx={{ color: '#ffffff', display: '-webkit-inline-box' }}>
            <LogoFull />
            <Grid>
              <Grid>
                <Typography>
                  Minu
                </Typography>
              </Grid>
              <Grid>
                <Typography >
                  Backstage
                </Typography>
              </Grid>
            </Grid>
          </Box>

        ) : (
          <LogoFull />
        )}
      </Link>
    </div>
  );
};

const SidebarItemWithPermission = () => {
  const { allowed } = usePermission({
    permission: catalogEntityCreatePermission,
  });

  return allowed ? (
    <SidebarItem
      icon={CreateComponentIcon}
      to="create"
      text="Create..."
    />
  ) : null
}

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        <SidebarItem icon={HomeIcon} to="catalog" text="Home" />
        <SidebarItemWithPermission />
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      <Typography style={{ color: '#ffffff', display: '-webkit-inline-box', marginLeft: '30px' }}>
        Version: {Package.version}
      </Typography>
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
