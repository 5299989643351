import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 45,
  },
  path: {
    
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.svg}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="300.000000pt"
      height="300.000000pt"
      viewBox="0 0 300.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <rect
        width="98%"
        height="98%"
        fill="#ffffff" 
      />
      <g
        transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
        fill="#555AD5"
        stroke="#none"
      >
        <path 
        className={classes.path}
        d="M0 1500 l0 -1500 1500 0 1500 0 0 1500 0 1500 -1500 0 -1500 0 0 -1500z m969 1147 c139 -49 259 -151 325 -276 66 -126 66 -125 66 -836 0 -589 1 -643 18 -674 48 -96 172 -105 236 -18 21 28 21 41 26 700 6 671 6 672 29 734 54 147 171 279 305 343 97 46 160 60 269 60 247 0 463 -147 554 -376 l28 -69 3 -910 c2 -677 0 -916 -9 -932 -21 -38 -56 -45 -232 -41 -158 3 -168 4 -189 26 l-23 23 -5 872 c-6 985 1 910 -86 943 -56 21 -106 9 -149 -36 l-30 -31 -5 -667 c-5 -653 -6 -668 -28 -732 -67 -198 -239 -359 -434 -405 -81 -19 -206 -19 -287 0 -196 47 -368 210 -429 410 -13 42 -17 156 -21 723 l-6 674 -37 33 c-49 45 -95 53 -150 26 -84 -40 -78 29 -78 -931 0 -914 1 -893 -49 -920 -28 -14 -336 -13 -364 2 -48 25 -46 -8 -47 929 0 871 0 888 21 955 62 204 231 370 425 419 93 23 260 15 353 -18z" />
      </g>
    </svg>
  );
};

export default LogoFull;
