import React from 'react';

import { Table, TableProps } from '@backstage/core-components';
import { CatalogTableRow } from './types';
import { CatalogTableToolbar } from './CatalogTableToolbar';

type PaginatedCatalogTableProps = {
  prev?(): void;
  next?(): void;
} & TableProps<CatalogTableRow>;

/**
 * @internal
 */
export function PaginatedCatalogTable(props: PaginatedCatalogTableProps) {
  const { columns, data, next, prev, title, isLoading, options } = props;
  
  return (
    <Table
      title={isLoading ? '' : title}
      columns={columns}
      data={data}
      options={{
        ...options,
        paginationPosition: 'both',
        pageSizeOptions: [],
        showFirstLastPageButtons: false,
        pageSize: Number.MAX_SAFE_INTEGER,
        emptyRowsWhenPaging: false,
      }}
      onPageChange={page => {
        if (page > 0) {
          next?.();
        } else {
          prev?.();
        }
      }}
      components={{
        Toolbar: CatalogTableToolbar,
      }}
      page={prev ? 1 : 0}
      totalCount={next ? Number.MAX_VALUE : Number.MAX_SAFE_INTEGER}
      localization={{ pagination: { labelDisplayedRows: '' } }}
    />
  );
}